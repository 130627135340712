var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-img',{style:({
    height:
      _vm.$vuetify.breakpoint.name == 'xs'
        ? '700px'
        : _vm.$vuetify.breakpoint.name == 'sm'
          ? '700px'
          : _vm.$vuetify.breakpoint.name == 'md'
            ? '100%'
            : _vm.$vuetify.breakpoint.name == 'lg'
              ? '100%'
              : '100%',
  }),attrs:{"src":require("./../../assets/images/login.jpg")}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pt-5":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-16":""}},[_c('v-flex',{attrs:{"xs3":"","sm3":"","md2":"","lg2":""}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs3":"","sm2":"","md2":"","lg1":""}},[_c('v-img',{attrs:{"src":require("./../../assets/logoeco.png"),"contain":"","height":"25px","width":"25px"}})],1),_c('v-flex',{attrs:{"xs8":"","sm6":"","md6":"","lg5":""}},[_c('span',{staticStyle:{"font-family":"pollerOneRegular","font-size":"14px","color":"#000"}},[_vm._v("ECOLedger")])])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[_c('v-flex',{attrs:{"xs3":"","sm2":"","md1":"","text-center":""}},[_c('v-img',{attrs:{"contain":"","height":"100px","width":"100px","src":require("./../../assets/icons/check.png")}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","py-5":""}},[_c('span',{staticStyle:{"font-family":"montserratRegular","font-size":"20px"}},[_vm._v("Check Your Email Inbox ")])]),_c('v-flex',{attrs:{"xs12":"","sm6":"","md5":""}},[_c('span',{staticStyle:{"font-family":"montserratRegular","font-size":"14px"}},[_vm._v("We have sent an Email to "+_vm._s(_vm.email)+" to verify your email address and activate your account. The enclosed link will expire in 24 hours.")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }