<template>
  <div>
    <v-img src="./../../assets/images/login.jpg" :style="{
      height:
        $vuetify.breakpoint.name == 'xs'
          ? '700px'
          : $vuetify.breakpoint.name == 'sm'
            ? '700px'
            : $vuetify.breakpoint.name == 'md'
              ? '100%'
              : $vuetify.breakpoint.name == 'lg'
                ? '100%'
                : '100%',
    }">
      <v-layout wrap justify-center>
        <v-flex xs12 pt-5>
          <v-layout wrap justify-center pt-16>
            <v-flex xs3 sm3 md2 lg2>
              <router-link to="/">
                <v-layout wrap justify-center>
                  <v-flex xs3 sm2 md2 lg1><v-img src="./../../assets/logoeco.png" contain height="25px"
                      width="25px"></v-img></v-flex>
                  <v-flex xs8 sm6 md6 lg5>
                    <span style="
                          font-family: pollerOneRegular;
                          font-size: 14px;
                          color: #000;
                        ">ECOLedger</span>
                  </v-flex>
                </v-layout>
              </router-link>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center py-10>
            <v-flex xs3 sm2 md1 text-center>
              <v-img contain height="100px" width="100px" src="./../../assets/icons/check.png">
              </v-img>
            </v-flex>
            <v-flex xs12 sm12 py-5>
              <span style="font-family: montserratRegular; font-size: 20px">Check Your Email Inbox
              </span>
            </v-flex>
            <v-flex xs12 sm6 md5>
              <span style="font-family: montserratRegular; font-size: 14px">We have sent an Email to {{ email }} to verify
                your email
                address and activate your account. The enclosed link will expire in 24 hours.</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-img>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    email() {
      return this.$route.query.email
    }
  },
  methods: {
  },
};
</script>

